// Libs.
import React from 'react';
// Deps.
import CheckoutComplete from '../../components/checkout/checkout-complete';
import Layout from '../../components/layout/layout';
import SEO from '../../components/layout/seo';
// Custom Libs.
import { getOrderDetailsFromQueryString } from '../../libs/commerce/checkout-utils';
// Assets.
import { checkoutPage } from '../../locale/locale.json';

const CheckoutCompletePage = (props) => {
  const orderDetails = getOrderDetailsFromQueryString(props.location.href);
  return (
    <Layout>
      <SEO title="Order Complete" />
      <CheckoutComplete
        cartType="fmk"
        pageTitle={'Order Confirmation'}
        displayMessage={checkoutPage.labels.confirmation.fmk}
        orderDetails={orderDetails}
      />
    </Layout>
  );
};

export default CheckoutCompletePage;
